.head-container {
  background: white;
  /* margin-top: 20px; */
  /* width:100%; */
  border-radius: 20px;
  padding: 20px 40px;
  margin: 50px 40px;
  width: 65%;
}
.play-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: relative;
  z-index: 999;
  margin: auto;
  bottom: 130px;
  background: rgba(243, 243, 245, 1);
  padding: 5px 0;
  border-radius: 8px;
  width: 100px;
  cursor: pointer;
}
.play-myp {
  bottom: 95px;
}
@media screen and (min-width: 1400px) {
  .play-myp {
    bottom: 130px;
  }
}
.play-icons-text {
  color: #4f4953;
  font-size: 14px;
  font-weight: normal;
  font-family: Inter-Medium, sans-serif;
  margin-top: 7px;
}
.share-repo {
  display: flex;
  justify-content: space-between;
}
.button {
  background: #362a41;
  font-family: var(--pf-medium);
  border-radius: 10px;
  position: absolute;
  right: 30%;
  width: 150px;
  height: 40px;
  color: white;
}
.button2 {
  background: #362a41;
  font-family: var(--pf-medium);
  border-radius: 10px;
  width: 150px;
  height: 40px;
  color: white;
  opacity: 1;
}
.button2Resume {
  background: rgba(225, 225, 228, 1);
  border-radius: 10px;
  /* width: 100px; */
  height: 40px;
  color: #362a41;
  font-size: 12px;
  font-family: Inter-Medium, sans-serif;
  font-style: italic;
  font-weight: normal;
  opacity: 1;
  position: absolute;
  /* bottom:60px; */
  right: 10px;
  top: 4px;
  margin-left: 47px;
}
.buttonNext {
  background: #362a41;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  color: white;
  opacity: 1;
}
.buttonVerify {
  color: #362a41;
  border-radius: 10px;
  width: 150px;
  height: 40px;

  opacity: 1;
}
.button6 {
  background: #362a41;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  color: white;
  opacity: 0.5;
}
.button2:hover {
  background: white;
  border: 1px solid #362a41;
  color: #362a41;
  font-weight: bold;
}
.font {
  font-size: 12px;
  /* font-weight: 600; */
  font-family: Inter-Medium, sans-serif;
}
.parent-check {
  margin-top: -5px;
}
.btn-head-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: -30px;
}
.btn-head {
  padding: 12px 10px;
  border-radius: 8px;
  color: #2fb766;
  font-size: 24px;
  font-weight: 800;
}
.btn-head-span {
  font-size: 16px;
  margin-top: 5px;
  color: #2fb766;
}
.myprofile-head {
  display: flex;
  gap: 400px;
}
.nav-item > .active .dots {
  padding-bottom: 12px;
  background-color: rgba(50, 249, 130, 1);
}
.nav-item > Link {
  cursor: pointer;
}
.pro-img {
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  margin-left: -15px;
  margin-bottom: 20px;
}
.cour-img {
  margin-left: -10px;
  margin-bottom: 20px;
}
.about-img {
  margin-left: -20px;
  margin-bottom: 20px;
}
.Edu-img,
.Exp-img {
  margin-bottom: 20px;
}
.link-text {
  color: #362a41 !important;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;

  position: relative;
  bottom: 0px;
  left: 0px;
}
.nav {
  position: relative;
  top: 200;
  right: 60px;
  padding: 60px 0px 50px 0px;
  width: 80%;
  cursor: pointer;
  /* background-color:#764F90; */
}
.navbar {
  margin: 0px 0 0px 0px;
  z-index: 1;
}
/* #about {
  margin-top: 60px;
} */

/* #education-popup{
    height:1200px;
} */
/* #courses{
    margin-bottom: 300px;
} */
.online-input {
  border: 1px solid rgba(149, 92, 202, 0.5);
  border-radius: 8px !important;
}
/* .rounded-checkbox {
  width:24px;
  height: 24px;
  border-radius: 8px;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: blue;
} */
.myprofile-content-head {
  display: flex;
  gap: 10px;
  position: relative;
  justify-content: space-between;
  width: 50%;
}
.pro-fr {
  width: 50%;
}
.disabledFollow {
  cursor: not-allowed;
  opacity: 0.5;
}
.myprofile-content-heads {
  display: flex;
  gap: 0px;
  position: relative;
  width: 40%;
}
.del-position {
  margin-left: 290px;
}
.Add-language {
  /* position:relative;
    top:0px;
    right:0px; */
  font-weight: normal;
  font-size: 16px;
  margin-top: 0px;
}
.Add-language:disabled {
  opacity: 0.5;
}
.Add-language2 {
  position: relative;
  left: 520px;
  top: 20px;
  font-weight: normal;
  font-size: 16px;
}
.Add-languageprofile {
  position: relative;

  font-weight: normal;
  font-size: 16px;
}
.Add-language2:disabled {
  opacity: 0.5;
}
.Add-languageprofile:disabled {
  opacity: 0.5;
}
.Add-language3 {
  position: absolute;
  right: 660px;
  /* top:20px;  */
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
}
.myprofile-content {
  display: flex;
  gap: 60px;
  margin-top: 10px;
}
.resume-list {
  display: flex;
  flex-direction: column;
}

.contentone {
  margin-top: 30px;
}
.myprofile-data {
  position: relative;
  left: 50px;
}
.myprofile-data-des {
  position: relative;
  left: 30px;
}

.myprofile-content-skills {
  position: relative;
  left: 70px;
}
.myprofile-content-data {
  position: relative;
  left: 25px;
}
.myprofile-content-links {
  position: relative;
  left: 160px;
  bottom: 40px;
  margin-bottom: 15px;
}
.myprofile-content-links > a {
  position: relative;
  bottom: 10px;
}
.myprofile-content-location {
  position: relative;
  left: 40px;
}
.myprofile-content-skills p:nth-child(2),
.myprofile-content-skills p:nth-child(3),
.myprofile-content-skills p:nth-child(4),
.myprofile-content-skills p:nth-child(5) {
  margin-top: -15px;
}
.hr2 {
  background-color: blue;
  position: relative;
  bottom: 25px;
  width: 580px;
}
.hr-line {
  background-color: #fff;
  position: relative;
  top: 7px;
  width: 100px;
  height: 2px;
  /* left: 5px; */
}
.dot-wrapper {
  display: flex;
  gap: 20px;
  margin-left: 30px;
  position: relative;
  bottom: 5px;
}
.dots {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
}
.dots .active {
  background-color: blue;
}
.head-content {
  border: 1px solid #362a41;
  padding: 5px 10px;
}
.modal-open {
  overflow: hidden;
}
.wrappers {
  background: rgb(189, 189, 189, 0.9);
  /* opacity:0.8; */
  width: 100vw;
  height: 100vh;
  margin-top: -30px;
  z-index: 3;
  overflow: auto;
  position: fixed;
}
.wrappers2 {
  background: rgb(189, 189, 189, 0.9);
  width: 100vw;
  height: 100vh;
  margin-top: -30px;
  z-index: 2;
  overflow: hidden;
  position: absolute;
  border-radius: 10px;
}
.wrappers3 {
  background: rgb(189, 189, 189, 0.9);
  width: 98vw;
  height: 100vh;
  margin-top: 0px;
  z-index: 2;
  overflow: hidden;
  position: absolute;
  border-radius: 10px;
  overflow-x: hidden;
}
.about-popup {
  background: #fff;
  border-radius: 20px;
  margin: 20px 520px 100px 370px;
  z-index: 1050;
  color: #362a41;
  font-weight: bold;
  /* padding:20px 40px; */
  /* width:1000px; */
  overflow-x: hidden;
  overflow-y: hidden;
  height: fit-content;
}

.about-end {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  margin: 40px 0 0 0;
  cursor: pointer;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 18px;
  height: 75px;
  /* width:100%; */
}
.about-ends {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  cursor: pointer;
  padding: 16px 16px 16px 16px;
  height: 75px;
  background: #fff;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  margin: 30px -21px 0px -21px;
}

/* .input-field{
    padding:10px 100px;
} */

.label-name {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin-bottom: 5px;
}
.label-names {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  /* text-transform: capitalize; */
  margin-bottom: 5px;
}
.label-name span {
  color: #ff4873;
  /* margin-left: 5px; */
  /* font-size: 22px; */
}
.label-name-error {
  font-family: "sans-seriff";
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: red;
}
.footer-header {
  display: flex;
  border-radius: 8px;

  background: #f4f4f6 !important;
  font-size: 12px !important;
  color: #362a41 !important;

  height: 48px;
  width: 100%;
}
.pro-tit {
  position: absolute;
}
.footer-headers {
  border-radius: 8px;
  border: 2px solid #ff5969;
  background: #f4f4f6;
  box-shadow: 0px 0px 0px 3px rgba(255, 89, 105, 0.12);
}
.footer-headers > option,
.footer-header > option {
  color: #362a41;
}
.ql-editor p {
  padding-right: 4rem !important;
  font-size: 12px;
  color: #4f4953;
  font-weight: normal;
}
.css-qbdosj-Input {
  font-size: 12px !important;
  color: #4f4953 !important;
  font-weight: normal !important;
}
.input-space {
  margin-top: 0px;
  width: 50%;
}

.close-icon {
  position: absolute;
  right: 580px;
  top: 30px;
  font-size: 20px;
  cursor: pointer;
}
.close-sym {
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 20px;
  cursor: pointer;
}

.Edu-data-wrapper {
  display: flex;
  gap: 40px;
  /* width:100%; */
}
.Edu-data-wrapper2 {
  display: flex;
  gap: 10px;
  background-color: #e4f9ff;
  border-radius: 6px;
  width: 72px;
  height: 40px;
  padding: 10px 20px;
}
.Edu-data-wrapper3 {
  display: flex;
  gap: 10px;
}
.star-rating-no {
  /* color: rgba(155, 147, 159, 1); */
  color: rgba(155, 147, 159, 1);
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 12px;
}
.skill-content {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 22.5px; /* 160.714% */
  letter-spacing: 0.28px;
  text-transform: capitalize;
  /* margin-left: 10px; */
}
.skill-content-rating {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 22.5px; /* 187.5% */
  letter-spacing: 0.24px;
  text-transform: capitalize;
  margin-left: 10px;
}
.skill-wrapper {
  border: 1px solid #dfdfdf;
  border-radius: 11px;
  width: 280px;
  margin-left: 30px;
  padding: 10px 0;
}
.Edu-data {
  /* position:absolute; */
  left: 150px;
  font-size: 16px;
  /* margin-top: 20px; */
}

.Edu-data2 {
  position: relative;
  bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.EduType {
  margin-top: 30px;
}

/* .hover-content-icon {
  position: relative;
  top: -10px;
  right: 60px;
  margin: -5px 0 0 -20px;
  background-color: #362a41;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  color: white;
  padding: 5px 13px;
  cursor: pointer;
} */
.hover-contents-icon {
  background-color: #362a41;
  color: white;
  width: 30px;
  border-radius: 50%;
  padding: 5px 13px;
  cursor: pointer;
}
.hover-content {
  color: #40444f;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
}
.content-icon {
  position: relative;
  margin-top: 20px;
  left: 10px;
}
.hover-content-icon .hover-content {
  display: none;
}

.hover-content-icon:hover .hover-content {
  display: flex;
  color: black;
  /* width: 300px; */
  border: 1px solid black;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  left: 0px;
  top: 20px;
  font-size: 12px;
  margin-left: -320px;
}
.Myprofile-Head {
  background-color: #fff;
  height: 86px;
  width: 85vw;
  position: absolute;
  left: 206px;
  top: 0px;
}
.Myprofile-Head > p {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  padding: 30px 80px;
}
/* .company-wrapper{
  display: flex;
} */
.myprofile-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-around; */
  gap: 25px 15px;
  padding:12px;
}
.Myprofile-containerNew {
  width: calc(96% / 3);
  height: fit-content;
  background: white;
  border-radius: 16px;
  cursor: pointer;
}

.myp-contact {
  /* color: #8652b6; */
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 15px 40px 10px 40px;
  cursor: pointer;
}
.contact-wrapper {
  background: url("../images/ContactBack.png");
  color: white;
  margin-top: 20px;
  padding: 20px;
}
.contact-details {
  margin-top: 5px;
}
.contact-container {
  margin-top: 10px;
}
.myp-contact-desig {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 240px;
}
.social-btn {
  color: #40444f;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  display: flex;
  align-items: center;
  background: #f4f4f6;
}
.social-text {
  color: #7b6194;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
}
.social-container {
  margin: 20px 0 0 38px;
}
.social-con {
  position: absolute;
  right: 100px;
  bottom: 180px;
}
.social-wrapper {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #f4f4f6;
  background: #f4f4f6;
  margin-bottom: 10px;
  margin-top: 10px;
  height:38px;
}
.assc-btn{
  background-color: #f4f4f6;
}
.assc-wrap{
  display: flex;
  gap:29px;
}
.assc-wraps{
  display: flex;
  gap:8px;
  align-items: center;
}
.assc-wraps img{
  width:24px;
  height:24px;
  margin-top: 13px;
}
.assc-desig{
  font-size: 14px;
  color:#4f4953;
  font-weight: 500;
  margin-top: 13px;
}
.fol-wrap {
  margin-top: 10px;
}
.contact-hr {
  width: 234px;
  height: 2px;
  flex-shrink: 0;
  stroke-width: 2px;
  background-color: #00a6e3;
  margin-top: 22px;
}
.myp-jobtitle {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%; /* 18.2px */
  text-transform: capitalize;
  margin: 10px 40px;
  max-width: 50vw;
  width: 90%;
}
.myp-wrapper {
  border-radius: 0 0 16px 16px;
  background-color: #fff;
  max-width: 77vw;
  width: 100%;
  height: 155px;
  padding: 100px 30px 20px 0px;
}
.myp-ass {
  cursor: pointer;
  border-radius: 16px;
  background: #f7f7f8;
  padding: 10px 10px 5px 10px;
  position: relative;
  bottom: 10px;
}
.otherp-wrappers2 {
  border-radius: 16px;
  background-color: #fff;
  max-width: 77vw;
  /* width: 100%; */
  padding-bottom: 10px;
  margin-top: 24px;
}
.myp-wrapper2 {
  border-radius: 16px;
  background-color: #fff;
  max-width: 77vw;
  width: 100%;
  padding: 10px;
  margin-top: 24px;
}
.myp-wrapper4 {
  border-radius: 16px;
  background-color: #fff;
  max-width: 77vw;
  width: 95%;
  padding: 10px;
  display: flex;
  gap: 30px;
  padding: 10px;
  position: relative;
  margin: 15px auto;
  height: fit-content;
  border: 1px solid #e3e2e6;
}
.myp-wrap4 {
  border-radius: 16px;
  background-color: #fff;
  max-width: 38vw;
  width: 100%;
  padding-bottom: 10px;
  margin-top: 24px;
  display: flex;
  gap: 30px;
  padding: 10px;
  position: relative;
  margin: 15px 0 15px 0px;
  height: fit-content;
  border: 1px solid #e3e2e6;
}
.desig-list {
  color: #a8a1ac;
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 10px 0 0px 0;
  background: rgba(244, 232, 255, 1);
  padding: 10px;
  height: fit-content;
  border-radius: 8px;
}
.desig-lists {
  color: #a8a1ac;
  font-family:
    General Sans,
    sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 15px 0 0px 0;
  background: rgba(244, 232, 255, 1);
  padding: 5px;
  height: fit-content;
  border-radius: 5px;
}
.doc-pend-wrap {
  display: none;
}
.doc-pend-wraps {
  display: flex;
  margin-top: 5px;
}
.myp-wrappers {
  border-radius: 0 0 16px 16px;
  background-color: #fff;
  max-width: 77vw;
  width: 100%;
  height: 205px;
  padding: 100px 30px 20px 0px;

  /* margin-top: 20px; */
}
.navbar-toggler {
  position: absolute;
  right: 30px;
  top: 7px;
}
@media (min-width: 768px) and (max-width: 1179px) {
  .myp-wrapper,
  .my-p-wrappers {
    max-width: 93vw;
    width: 100%;
  }
}

.myp-data-wrapper {
  position: relative;
  bottom: 90px;
  left: 180px;
  /* display: flex; */
  /* justify-content: space-evenly; */
}
/* .myp-in-wrapper {
  width: 450px;
} */
.star-wrapper {
  position: absolute;
  right: 50px;
  top: 10px;
  /* width:108px; */
  padding: 8px 16px;
  margin: 20px 0 20px 0;
  border-radius: 24px;
  border: 1px solid rgba(39, 39, 39, 0.5);
  background: rgba(31, 31, 31, 0.3);
}
.rating {
  position: relative;
  top: 3px;
  right: 5px;
  color: white;
  font-weight: normal;
}
.social-data {
  /* width: 326px; */
  height: 104px;
  flex-shrink: 0;
  border-radius: 18px;

  margin: 10px 0 0 0;
}
.block-icon {
  margin: -5px 10px 0 0;
}
.block-cont {
  cursor: pointer;
}
.social-datas {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  color: #a8a1ac;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  padding: 20px;
}
.social-count {
  margin: 10px;
  color: #362a41;
  text-align: center;
}
/* .myp-wrapper2 {
  border-radius: 24px;
  background-color: #fff;
  max-width:93vw;
  width:77vw;
  height: fit-content;
  padding-bottom: 20px;
  margin: 24px 0; */
/* border: 1px solid #e3e2e6; */
/* } */

/* .about-sec {
  width: 70%;
} */
.post-sec {
  width: 30%;
}
.Edu-logo {
  margin: 0px 0 30px 40px;
}
.Edu-logo img {
  width: 80px;
  height: 85px;
}
.Edu-logo-remove {
  display: none;
}
.Post-wrapper {
  display: flex;
  justify-content: space-between;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-top: 20px;
}
.myp-wrapper3 {
  border-radius: 16px;
  background-color: #fff;
  max-width: 77vw;
  width: 100%;
  /* width:270px; */
  height: fit-content;
  padding: 10px 40px 30px 40px;
  margin: 24px 0 0 0;
}
.myp-wrapper3 > img {
  padding: 40px 20px;
}
.Myprofile-topcontainer {
  background: url("../images/Rectangle\ 988.png");
  backdrop-filter: blur(33.5px);
  width: 100%;
  height: 74px;
  border-radius: 16px;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

.myp-avatar {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  /* border: 3px solid #fff;
  border-spacing: 10px;
  filter: drop-shadow(0px 4px 16px rgba(54, 42, 65, 0.12)); */
  position: relative;
  top: 20px;
  left: 15px;
  background-color: #f7f7f8;
}
.myp-about-avatar {
  width: 95px;
  height: 85px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-spacing: 10px;
  filter: drop-shadow(0px 4px 16px rgba(54, 42, 65, 0.12));
  position: relative;
  top: 0px;
  left: 30px;
  background-color: #f7f7f8;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: none !important ;
  padding: 165px;
}
::-webkit-scrollbar-track {
  background-color: none !important;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdc0;
}
.down-img {
  margin-left: 20px;
  border: 1px solid #f4f4f6;
  padding: 8px;
  border-radius: 8px;
}
.resume-down-img {
  padding: 8px;
  border-radius: 8px;
}
.resume-cont {
  width: 100%;
  bottom: 25px;
}
.resume-icon-wrap {
  display: flex;
  justify-content: end;
  margin-top: -20px;
}
.myp-name {
  display: flex;
  gap: 0px;
  margin: 5px 0 5px 28px;
  /* color: #8653b6; */
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 10px;
}

.myp-name > p:nth-child(1) {
  margin-left: 28px;
}
.myp-lang {
  margin-top: 5px;
}
.myp-lang:nth-child(1) {
  margin-top: 0px;
}
.edit-btn {
  display: flex;
  justify-content: flex-end;
  margin: 30px;
}
.myp-editbtn {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #362a41;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.bio {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 130%; /* 18.2px */
  margin-top: 10px;
  padding-left: 38px;
  width: 80%;
}
.myp-detail-wrapper {
  display: flex;
  gap: 20px;
}
.myp-details {
  display: flex;
  gap: 20px;
  padding: 15px 38px 10px 33px;
}
.myp-details2 {
  display: block;
  padding: 8px 38px 10px 38px;
}
.myp-details-icon {
  display: flex;
  gap: 5px;
  /* margin:7px 0; */
}
.myp-details-icon > img {
  width: 24px;
  height: 24px;
}
.myp-details > p,
.myp-details-icon > p {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-top: 5px;
}
.myp-skills {
  display: flex;
  gap: 10px;
  padding-left: 38px;
}
.myp-skills .heading {
  color: #4f4953;
  font-family:
    General Sans,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 10px;
}
.sub-heading {
  display: flex;
  gap: 20px;
}
.sub-heading > p > span {
  display: inline-block;
  padding: 8px 16px;
  /* margin-top: 10px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #f4e8ff;
  color: #4f4953;
}
.myp-worklinks-wrapper {
  padding-left: 38px;
  display: flex;
  flex-flow: wrap;
  gap: 10px;
}
.myp-worklinks {
  /* width: 95%; */
  height: fit-content;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f4f4f6;
  padding: 10px 20px 10px 20px;
  margin: 0px 0 0px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.myp-worklinks > p,
.myp-worklinks > a {
  color: #40444f;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
}
.About-wrapper {
  display: flex;
  gap: 20px;
}
.About-wrappers {
  display: block;
}
.myp-desc-wrapper {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: #f4f4f6;
  padding: 10px 20px;
  margin: 5px 10px 10px 38px;
  border-radius: 5px;
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 22.4px */
  /* text-transform: capitalize; */
}
.myp-desc-wrapper > p {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 22.4px */
}
.f12 {
  font-size: 12px;
  font-family: Inter-Medium, sans-serif !important;
  margin-top: 10px;
  color: #ff596a !important;
}
.fw {
  font-weight: 700;
}
.exp-desc {
  width: fit-content;
  height: fit-content;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: #f4f4f6;
  padding: 10px 28px;
  margin: 0px 30px 0 28px;
  border-radius: 5px;
}
.exp-desc-content {
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 22.4px */
  /* text-transform: capitalize; */
}
.Edu-data-wrappers {
  margin-top: 0px;
  width: 100%;
  padding-left: 12px;
}
.Edu-wrapper,
.Edu-icon-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Edu-head {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  padding: 20px 38px 0px 38px;
}
.Edu-header {
  color: #8653b6;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  padding: 20px 38px 0px 38px;
}
.Add-btn-content {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #362a41;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin: 20px 30px 0px 30px;
}
.Edu-col-name {
  /* color: #8653b6; */
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 28px;
  margin: 10px 0;
}
.Edu-deg {
  color: #4f4953;
  font-family:
    General Sans,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 0px 0 0px 28px;
}
.icons-container,
.mp-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.Edu-detail-wrapper {
  display: flex;
  gap: 40px;
  margin: 10px 0 0 28px;
  width: 100%;
}
.Edu-detail-wrappers {
  display: block;
  gap: 40px;
  margin: -10px 0 0 38px;
}
.Edu-Popup {
  border-radius: 24px !important;
}
.EduPopup-head {
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
}
.Edu-Popup-wrapper {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 10px 0px;
}
.course-head {
  margin: 0 0 10px 0;
}
.company {
  color: #8653B6;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  /* margin-left: 40px; */
  cursor: pointer;
}
.location {
  color: #a8a1ac;
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}
.xakal-add {
  color: #8c8c8c;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
}
.xakal-address {
  background-color: #e6e6e6;
  font-family: var(--pf-regular);
  color: var(--this-primary);
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  border-radius: 8px 0 0 8px;
  height: 48px;
  padding: 20px;
}
.resend-content {
  color: #a8a1ac;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}
.resend {
  color: #8652b6;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.class-section {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.table-container {
  margin-top: 20px; /* Adjust the top margin as needed */
  margin-left: 30px;
}

.language-table {
  border-collapse: collapse;
  width: 100%;
}

.language-table th,
.language-table td {
  padding: 8px; /* Adjust padding for cell content */
}

.language-table th {
  /* background-color: #f2f2f2;  */
  color: #362a41;
  font-family: "Inter-Medium,sans-serif";
}

.language-table td {
  color: #362a41;
  font-family: "Inter-Medium,sans-serif";
}

/* Optional: Add space between columns */
.language-table th:not(:last-child),
.language-table td:not(:last-child) {
  margin-right: 10px; /* Adjust as needed for the space between columns */
}
.ser-head-wrapper {
  display: flex;
  /* justify-content: center; */
  /* margin: 20px 10px; */
}
.ser-head {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.button4 {
  background: white;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  border: 1px solid #362a41;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  /* opacity:0.5; */
}
.button9 {
  background: white;
  width: auto;
  height: 40px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  /* opacity: 0.5; */
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #e3e2e6;
}
.button9:hover {
  background: white;
  border-radius: 10px;
  width: auto;
  height: 40px;
  border: 1px solid #362a41;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  /* opacity: 0.5; */
  padding: 8px;
}
.button10 {
  color: #4f4953;
  border: 1px solid #f4f4f6;
  border-radius: 10px;
  width: 130px;
  height: 40px;
  background: transparent;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  /* opacity: 0.5; */
  padding: 12px 24px;
  margin: 5px 30px 5px 0;
}
.button10.hold {
  border: 2px solid orange; /* Orange border for hold slots */
  background-color: #f8f8f8; /* Optional: to differentiate from other states */
}
.button10.active {
  background: white;
  border-radius: 10px;
  width: 130px;
  height: 40px;
  border: 2px solid #362a41;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  /* opacity: 0.5; */
  padding: 12px 24px;
}
.button10.booked {
  background-color: #f8d7da; /* Booked state background color */
  color: #721c24; /* Booked state text color */
  border-color: #f5c6cb; /* Booked state border color */
  font-size: 12px;
}
.button10.slot-booked {
  font-size: 12px;
}
.button9.active {
  background: white;
  color: #362a41;
  border: 1px solid #362a41;
}
.button7 {
  border-radius: 6px;
  background: #f4e8ff;
  width: auto;
  color: #8653b6;
  font-family: Inter-Medium, sans-serif;
  padding: 8px;
  display: flex;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 30px;
  margin-top: 10px;
}
.button8 {
  width: auto;
  color: #FF3E3E;
  font-size: 14px;
  font-family: Inter-Medium, sans-serif;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 30px;
  margin-top: -95px;
}
.button8-act{
  position: absolute;
  right: 30px;
  margin-top: -85px;
  color: #20D203;
  font-size: 14px;
}
.myp-inter-wrap {
  margin-top: 20px;
}
.button07 {
  color: white;
  border-radius: 10px;
  width: 255px;
  height: 40px;
  background: #362a41;
  font-family: Inter-Medium, sans-serif;
  /* opacity: 0.5; */
  padding: 12px 24px;
  /* width: auto; */
}
.button07:hover {
  color: #362a41;
  background-color: #fff;
  border: 1px solid #362a41;
}
.prodInterview {
  font-size: 20px;
  font-family: Inter-Medium, sans-serif;
}
.imageProd {
  gap: 10px;
  position: relative;
  display: flex;
}
.imageData {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}
.icon-part {
  position: relative;
  display: flex;
  gap: 20px;
}
.notification-badge {
  position: relative;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  top: -8px;
  right: -8px;
}
.pro-cour-wrapper {
  display: flex;
  gap: 30px;
  width: 100%;
  margin-right: 1rem;
}
.navbar-nav {
  padding: 10px;
  border-radius: 10px;
  height: 90vh;
  z-index: 1002;
  border-right: var(--this-border);
}
.css-ohyacs {
  padding: 0 !important;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper,
.MuiDialog-paperScrollPaper {
  border-radius: 16px !important;
}

/* .MuiDialogContent-root:first-child{
  padding:0px !important;
} */

.css-rnmm7m-MuiPaper-root-MuiDialog-paper > svg {
  border: none !important;
  background-color: grey !important;
}

@media screen and (max-width: 1024px) {
  .Myprofile-Head {
    left: 0px;
    width: 100vw;
  }
  .Myprofile-Head > p {
    padding: 30px;
  }
  .navbar-nav {
    overflow: hidden;
    transition: 1s;
  }
}
@media screen and (max-width: 1200px) {
  .myp-detail-wrapper {
    display: block;
  }
  .Edu-detail-wrapper {
    gap: 10px;
    margin-top: 15px;
  }
  .sub-heading > p > span {
    margin-top: 10px;
  }
}
@media screen and (min-width: 601px) and (max-width: 1023px) {
  .myp-wrapper {
    height: 200px;
  }
  .myp-wrappers {
    height: 245px;
    max-width: 93vw;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .myp-wrapper {
    height: 170px;
  }
  .myp-wrappers {
    height: 215px;
  }
  .myp-data-wrapper {
    display: block;
  }
}
@media screen and (min-width: 601px) and (max-width: 820px) {
  .css-zw3mfo-MuiModal-root-MuiDialog-root {
    /* bottom:500px !important; */
    right: 50px !important;
  }
  .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
    width: calc(100% - 120px) !important;
  }
  .myprofile-content-head {
    width: 70%;
  }
}
.mp-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.mp-card-header .title {
  font-size: 24px;
  font-family: var(--pf-medium);
  color: black;
}
.mp-desig,
.mp-location {
  font-size: 16px;
  font-family: var(--pf-medium);
  color: var(--this-third);
}
.mp-row {
  display: flex;
  padding: 0 25px 0px 25px;
}
.mp-rows{
  display: flex;
  width:100%;
  justify-content: space-between;
}
.btn-mpb {
  background-color: var(--this-primary);
  padding: 8px 24px !important;
  font-size: 16px;
  font-family: var(--pf-medium);
  color: white !important;
  border-radius: 8px;
  cursor: pointer;
}
.mp-about-row {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.mp-about-rows {
  display: flex;
  gap: 10px;
}
.mp-c {
  font-size: 14px;
  font-family: var(--pf-regular);
  font-weight: normal;
  margin-bottom: 0;
  overflow-x: hidden;
}
.mp-so-link {
  font-family: var(--pf-medium);
  font-size: 14px;
  color: #40444f;
  margin-bottom: 0;
}
.mp-at {
  font-size: 18px;
  color: #362a41;
  font-family: var(--pf-medium);
}
.mp-card {
  background-color: white;
  padding: 15px 25px;
  border-radius: 16px;
  margin-bottom: 25px;
}
.mp-c-card {
  background-color: #f4f4f6;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 8px;
  max-width: 60vw;
  display: flex;
  flex-wrap: wrap;
}
.prof-star {
  margin-top: -5px;
}
@media screen and (max-width: 600px) {
  .mp-desig,
  .mp-c,
  .mp-so-link {
    font-size: 12px;
  }
  .mp-c-card {
    max-width: 100vw;
  }
  .mp-at {
    font-size: 14px;
  }
  .mp-row,
  .mp-about-row {
    display: block;
  }
  .mp-card {
    padding: 15px;
  }
  .btn-mpb {
    padding: 4px 8px !important;
    font-size: 12px;
  }
  .button8 {
    margin-top: 0px;
    position: relative;
    right: 10px;
  }
  .button7 {
    position: relative;
    right: 0px;
    margin: 50px 0 0 0px;
  }
  .myp-wrapper {
    height: 430px;
    max-width: 91vw;
  }
  .myp-wrappers {
    height: 475px;
    max-width: 91vw;
  }
  .myp-data-wrapper {
    display: block;
    top: 50px;
    left: 0px;
  }
  .myp-wrapper2 {
    width: 92vw;
  }
  .myp-wrapper4 {
    width: 92vw;
    display: block;
  }
  .Myprofile-topcontainer {
    max-width: 91vw;
  }
  .About-wrapper,
  .Edu-data-wrapper {
    display: block !important;
  }
  .myp-details,
  .myp-skills,
  .Edu-detail-wrapper,
  .pro-cour-wrapper {
    display: block;
  }
  .mp-card-header .title {
    font-size: 20px;
  }
  .myp-desc-wrapper {
    margin: 5px 20px 5px 30px;
    font-size: 12px;
  }
  .myp-worklinks {
    width: 95%;
    font-size: 12px;
  }
  .heading {
    width: 115px;
  }

  .pro {
    margin: 10px !important;
  }
  .nav-link {
    background-color: transparent;
  }
  .myp-wrapper3 {
    max-width: 92vw;
    padding: 10px 0 30px 0;
  }
  .Edu-Popup-wrapper,
  .myprofile-content-head {
    display: block;
    width: 100%;
  }
  .Edu-popup-cont {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 10px 0px;
  }
  .pro-fr {
    width: 100%;
  }
  .input-space,
  .input-spaces {
    width: 100%;
  }
  .space {
    margin-top: 10px;
  }
  .hide {
    display: none;
  }
  .mp-wrapper .job-nav-link {
    padding: 25px 0;
    display: none;
  }
  .btn-head-wrapper {
    margin-top: 10px;
  }
  .social-datas {
    display: block;
    text-align: center;
  }
  .social-data {
    height: fit-content;
  }
}
/* tab & desktop */
@media (min-width: 576px) {
  .myprofile-wrapper .in-content-wrapper {
    padding: 10px 20px;
  }
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0px !important;
  }
  .css-ypiqx9-MuiDialogContent-root,
  .css-1ty026z {
    padding: 0px !important;
  }
  .css-hlj6pa-MuiDialogActions-root {
    padding: 0px !important;
  }
  .MuiDialogContent-root {
    padding: 0px !important;
  }
}

.mp-header {
  background-color: white;
  border-radius: 16px;
  margin-bottom: 25px;
  margin-top: 85px;
}
.mp-i-top {
  position: relative;
}
.star-holder {
  background-color: #1f1f1f4d;
  border: 1px solid #27272780;
  border-radius: 30%;
  color: white;
  font-family: var(--pf-medium);
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 7px;
}
.mp-top-img {
  width: 100%;
  height: 173px;
  object-fit: cover;
  border-radius: 16px;
  border-bottom-right-radius: 0;
}
.mp-img-container {
  background-color: #f4f4f6;
  border-radius: 50%;
  padding: 10px;
  margin-top: -80px;
  position: relative;
  width: 190px;
  height: 190px;
}

.mp-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}
.mp-cont-wrapper {
  padding: 20px 10px;
  display: flex;
}
.mp-cont-wrappers {
  padding: 20px 10px;
  display: block;
}

.mp-title-row {
  display: flex;
  align-items: center;
  font-family: var(--pf-medium);
  gap: 25px;
  margin-bottom: 6px;
}
.mp-title {
  font-size: 24px;
  color: #221b52;
}
.mp-cd {
  font-size: 14px;
  color: #271f57;
  cursor: pointer;
}
.mp-location {
  font-size: 13px;
  font-family: var(--pf-regular);
  font-weight: normal;
  color: var(--this-primary-lite);
}
.mp-wrapper .job-nav-container {
  padding: 0 25px;
}
.mp-wrapper .job-nav-link {
  padding: 25px 0;
}
.myp-content {
  padding-left: 0px;
}

.mp-ap-wrapper {
  flex-shrink: 0;
}
.mp-ap-container {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0px 4px 16px 0px #362a411f;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mp-ap-img {
  width: 52.5px;
  height: 52.5px;
  border-radius: 50%;
  object-fit: cover;
}
.mp-p-desig {
  color: var(--this-third);
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
}
.mp-card-body .job-preference {
  color: var(--this-third);
  font-family: var(--pf-medium);
}
.mp-c ul,
.mp-c li,
.mp-c p,
br {
  display: inline;
}
.rm-btn {
  color: #6262f5;
  cursor: pointer;
}
.mp-c-li {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.mp-so-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.mp-ap-cont-wrapper {
  flex-grow: 1;
}
.mp-actions img {
  cursor: pointer;
}
.repo-wrapper {
  background-color: #fff;
  border-radius: 24px;
  padding: 15px 30px 15px 30px;
}
.repo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.repo-title {
  font-size: 24px;
  color: var(--this-primary);
  font-family: var(--pf-medium);
}
.btn-repo-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.btn-repo,
.btn-repo:hover,
.btn-repo:focus {
  color: var(--this-primary) !important;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e3e2e6 !important;
  padding: 8px 6px !important;
  font-size: 12px;
  font-family: var(--pf-regular) !important;
  font-weight: normal;
  box-shadow: none;
}
.btn-repo:hover,
.btn-repo:focus {
  border-color: var(--this-primary) !important;
}
.repo-card {
  box-shadow: 0px 24px 70px 0px #24272d1a;
  border: 2px solid #f4f4f6;
  background-color: #fff;
  border-radius: 16px;
}
.rc-img {
  width: 100%;
  height: 152px;
  object-fit: cover;
  border-radius: 16px;
}
.rc-cont {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.rc-title {
  font-size: 16px;
  font-family: var(--pf-medium);
  color: #05204d;
  cursor: pointer;
}
.rc-option,
.rc-option:hover,
.rc-option:focus,
.rc-option:active {
  padding: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
.rc-option::after {
  display: none;
}
.rc-dropdown-menu {
  background-color: #fff;
  box-shadow: 0px 4px 30px 0px #0000004d;
  border-radius: 16px;
  padding: 10px;
}
.rc-dropdown-menu .dropdown-item,
.rc-dropdown-menu .dropdown-item:hover {
  padding: 6px 0;
  font-size: 14px;
  font-family: var(--pf-regular);
  font-weight: normal;
  color: var(--this-third);
  background-color: white;
}
.rc-dropdown-menu .delete-link {
  color: #fd2145;
}
.repo-body {
  margin: 15px 0px 55px 0px;
}
.repo-body .slick-slide > div {
  margin: 0 10px;
}
.repo-body .slick-list {
  margin: 0 0px 0 -15px;
  box-shadow: 0px 10px 75px 0px #24272d1a;
}
.repo-body .slick-track {
  margin: 10px 0 10px 10px;
}
.repo-body .slick-dots {
  bottom: -40px;
}
.repo-body .slick-dots li {
  margin: 0;
}
.repo-body .slick-dots li button:before {
  font-size: 10px;
  color: #dddfe4;
  opacity: 1;
}
.repo-body .slick-dots li.slick-active button:before {
  font-size: 16px;
  color: var(--this-primary);
  opacity: 1;
}
.repo-body .slick-prev:before,
.repo-body .slick-next:before {
  display: block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.repo-body .slick-prev:before {
  content: "";
  background-image: url(../images/icons/chervron-left-primary.svg);
}
.repo-body .slick-next:before {
  content: "";
  background-image: url(../images/icons/chervron-right-primary.svg);
}
@media (min-width: 1500px) {
  .rc-img {
    height: 220px;
  }
  .myprofile-row {
    gap: 35px 35px;
  }
  .Myprofile-containerNew {
    width: calc(92% / 3);
    /* margin:auto; */
  }
}
@media (min-width: 768px) and (max-width:820px) {
  .myprofile-row{
    margin-left: 30px;
  }
}
.rd-wrapper {
  background-color: white;
  border-radius: 24px;
  padding: 15px;
}
.rd-card {
  border: 1px solid #e3e2e6;
  /* border-bottom: 0; */
  padding: 8px;
  border-radius: 16px;
}
.rd-card-footer {
  padding: 15px;
  border: 1px solid #e3e2e6;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  text-align: center;
}
.rd-card,
.rd-card-footer {
  max-width: 680px;
  margin: 5px auto;
}
.rd-header {
  display: flex;
  align-items: center;
}
.rd-header,
.rd-body {
  padding: 10px 15px;
}
.rd-profile {
  display: flex;
  align-items: center;
  gap: 20px;
}
.rd-pro-img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.rd-pro-title {
  font-size: 18px;
  color: var(--this-primary);
  font-family: var(--pf-medium);
}
.rd-header .price {
  font-size: 16px;
  font-family: var(--pf-medium);
  color: var(--this-secondary);
  background-color: #f4e8ff;
  border-radius: 5px;
  padding: 10px 8px;
  margin-left: auto;
}
.rd-header .price i {
  font-size: 12px;
}
.timer-row {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-family: var(--pf-medium);
  color: var(--this-primary);
  margin-left: auto;
}
.timer-row img {
  width: 16px;
}

.rd-download-link,
.rd-download-link:hover {
  color: var(--this-secondary);
  font-size: 12px;
  font-family: var(--pf-medium);
  display: inline-flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.rd-download-link img {
  width: 16px;
}
.rd-video-container {
  position: relative;
  width: 100%;
  height: 280px;
  overflow: hidden;
  border-radius: 12px;
}
.rd-video {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* box-shadow: 2px 2px 2px 2px #05204d; */
  padding-top: 10px;
}
.video-poster {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-poster img {
  width: 95px;
}
.rd-video-container.active .video-poster {
  display: none;
}
.react-datepicker__day--disabled {
  cursor: default;
  color: #362a411a;
}

.company-wrapper .company-name {
  font-size: 16px;
  font-family: var(--pf-medium);
  color: var(--this-secondary);
  text-align: center;
  margin-bottom: 10px;
}
.company-wrapper .company-des {
  font-size: 12px;
  font-family: var(--pf-regular);
  color: var(--this-primary-lite);
  text-align: center;
}

@media (max-width: 992px) {
  .Myprofile-containerNew {
    width: calc(92% / 2);
  }
  .navbar-nav {
    background-color: #fff;
    margin-top: 50px;
    width: 230px;
    box-shadow:  2px 4px 8px 0px #4f4953;
    border-top: 1px solid grey;
  }
  #basic-navbar-nav {
    transition: all 0.75s;
    place-content: right;
    margin-left: -5.2rem;
  }
}
.form-control,
.css-1jqq78o-placeholder,
.css-b62m3t-container {
  font-family: var(--pf-regular) !important;
  font-weight: 550 !important;
  font-size: 12px !important;
}
.image-ats {
  width: 145px;
  border-radius: 100px;
  height: 145px;
}
.pending-flex {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}
.pending-push {
  margin: 20px;
  display: flex;
  gap: 20px;
}
.image-push {
  width: 150px;
  height: 150px;
}
.flex-button {
  position: relative;
  display: flex;
}
.main-div-images {
  display: flex;
  gap: 10px;
}
.images-avatar-one {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.slot-update-time {
  position: relative;
  display: flex;
  gap: 30px;
}
.slot-update-times {
  position: relative;
  display: flex;
  gap: 10px;
}
.upload-resume-main {
  display: flex;
  justify-content: center;
}
.upload-resume-main > img{
  width:360px;
  margin:75px 0 28px 0;
}
.upload-resume-head {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top:10px;
  right:20px;
  width:24px;
  height:24px;
  cursor: pointer;
}
.content-resume-div {
  text-align: center;
}
.content-resume-div h3{
  font-size: 30px;
  font-weight: 700;
}
.content-resume-div p{
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 96px;
}
.button-resume {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 1px 2px 4px 5px;
}
.btn-resume{
  margin:0 45px;
}
.confirmButtonResume {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 8px;
  font-weight: normal;
  font-size: 16px;
  color: white;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 50%;
  height: 40px;
  cursor: pointer;
  background: #362a41;
  margin-bottom: 24px;
}
.confirmButtonResume:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 8px;
  font-weight: normal;
  font-size: 16px;
  background-color: #39c371;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 50%;
  height: 40px;
  cursor: pointer;
  color: white;
}
.cancelButtonResume {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 8px;
  font-weight: normal;
  font-size: 16px;
  color: white;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 50%;
  height: 40px;
  cursor: pointer;
  background: #fd2145;
}
.cancelButtonResume:hover{
  background-color: #810015;
}
.confirmButtonAnother {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  color: #362A41;
  padding: 12px;
  width: 90%;
  height: 40px;
  cursor: pointer;
  background: #E1e1E1;
  border: 1px solid #362A41;
  margin: 0 40px 40px 40px;
}
.confirmButtonAnother:hover{
  background-color: #fff;
border: 2px solid #362A41;
}
